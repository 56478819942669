form {
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  form .toolTip {
    display: flex;
    position: relative;
    flex: 1 1 100%; }
    form .toolTip .toolTip-image {
      width: 20px; }
  form .toolTip .toolTipText {
    visibility: hidden;
    width: 250px;
    background-color: black;
    color: #ffffff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    top: -5px;
    right: 2%;
    z-index: 1; }
  form .toolTip:hover .toolTipText {
    visibility: visible; }
  form .field-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0 7.5px;
    flex: 1 1 100%; }
    form .field-group label {
      flex: 1 1 100%;
      font-size: 14px;
      line-height: 28px; }
    form .field-group .form-input-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      flex: 1 1 auto; }
      form .field-group .form-input-container .input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%; }
        form .field-group .form-input-container .input-group .input-group-text {
          display: flex;
          align-items: center;
          padding: 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          text-align: center;
          white-space: nowrap; }
        form .field-group .form-input-container .input-group input {
          position: relative;
          flex: 1 1 auto;
          width: 1%;
          min-width: 0; }
    form .field-group .uploaded_images {
      margin-top: 1rem; }
      form .field-group .uploaded_images .item {
        text-align: center; }
        form .field-group .uploaded_images .item img {
          max-height: 150px; }

.rating-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.5rem 1rem; }
  .rating-container label {
    flex-basis: 100%; }
  .rating-container .field-group.checkbox .form-input-container {
    display: flex;
    flex-direction: column; }
    .rating-container .field-group.checkbox .form-input-container input[type='radio'] {
      flex: 1 1 auto;
      height: 2rem;
      width: 2rem;
      padding: unset;
      appearance: none;
      background-color: #d5ebe9;
      border: 1px solid #d5ebe9;
      border-radius: 50%;
      background-clip: content-box;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .rating-container .field-group.checkbox .form-input-container input[type='radio']:checked {
        background-color: unset;
        border: unset; }
      .rating-container .field-group.checkbox .form-input-container input[type='radio']:disabled {
        opacity: 0.3; }
      .rating-container .field-group.checkbox .form-input-container input[type='radio']:focus {
        outline: 0; }
    .rating-container .field-group.checkbox .form-input-container label {
      font-size: 1rem;
      font-weight: normal; }

.field-group.radio {
  justify-content: center;
  text-align: center; }
  .field-group.radio .item {
    text-align: left; }
  .field-group.radio .item-equal {
    display: flex;
    align-items: center; }
    .field-group.radio .item-equal input {
      flex: 0 1 auto; }
    .field-group.radio .item-equal label {
      text-align: left;
      padding-left: 1rem; }

label {
  margin: 5px 10px 5px 0;
  flex: 0 0 100%; }

input {
  font-family: inherit;
  padding: 1rem 0.75rem;
  flex: 0 0 100%;
  box-sizing: border-box;
  border: 1px solid #231f20;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 5px 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  input:disabled {
    opacity: 0.3; }
  input:focus {
    outline: 0; }

input[type='radio'] {
  height: 2rem;
  width: 2rem;
  padding: unset;
  appearance: none;
  background-color: #d5ebe9;
  border: 8px solid #d5ebe9;
  border-radius: 50%;
  background-clip: content-box;
  -webkit-appearance: none;
  -moz-appearance: none; }
  input[type='radio']:checked {
    background-color: #e885bf; }
  input[type='radio']:disabled {
    opacity: 0.3; }
  input[type='radio']:focus {
    outline: 0; }

input[type='checkbox'] {
  height: 2rem;
  min-width: 2rem;
  padding: unset;
  margin: unset;
  flex: unset;
  background-color: #d5ebe9;
  border: 1px solid #d5ebe9;
  border-radius: 50%;
  background-clip: content-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; }
  input[type='checkbox']:checked {
    background-color: #e885bf;
    border: 1px solid #e885bf; }
  input[type='checkbox']:disabled {
    opacity: 0.3; }
  input[type='checkbox']:focus {
    outline: 0; }

select {
  font-family: inherit;
  padding: 1rem 0.75rem;
  flex: 1 1 100%;
  box-sizing: border-box;
  border: 1px solid #231f20;
  border-radius: 5px;
  background-color: #ffffff;
  margin: 3px 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none; }
  select:disabled {
    opacity: 0.3; }
  select:focus {
    outline: 0; }

textarea {
  font-family: inherit;
  padding: 1rem 0.75rem;
  flex: 0 0 100%;
  box-sizing: border-box;
  border: 1px solid #231f20;
  border-radius: 5px;
  margin: 5px 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none; }
  textarea:disabled {
    opacity: 0.3; }
  textarea:focus {
    outline: 0; }

button[type='submit'] {
  flex: 0 0 100%; }

label.required:after {
  content: ' *';
  color: #de274f; }

.form-error {
  flex: 1 1 100%;
  color: #c72c41;
  min-height: 25px;
  font-size: 14px;
  line-height: 28px; }

.form-warning {
  color: #ffdc34; }

.field-group.toggle {
  block-size: 90px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 2.3rem 1rem 0rem 1rem; }
  .field-group.toggle label {
    max-block-size: 100%; }
    .field-group.toggle label span {
      margin-right: 3rem; }

.field-group.checkbox .form-input-container {
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  display: flex; }
  .field-group.checkbox .form-input-container label {
    flex: 0 1 auto; }

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

@media only screen and (min-device-width: 0px) and (max-device-width: 1024px) {
  label {
    padding: unset; }
  .field-group label {
    height: unset; }
  .field-group.toggle {
    margin: 1rem; }
    .field-group.toggle label {
      max-block-size: unset; }
      .field-group.toggle label span {
        margin-right: unset; } }

.header-container {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap; }

.fields-container {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap; }

.form-row {
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap; }
  .form-row .field-group {
    flex: 1 1 40%; }

@media only screen and (min-device-width: 0px) and (max-device-width: 1024px) {
  .form-row .field-group {
    flex: 1 1 100%; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .form-row {
    display: flex;
    flex: 1 1 100%;
    flex-wrap: wrap; }
    .form-row .field-group {
      flex: 1 1 40%; } }
